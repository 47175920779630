import { Icon } from '@/components/icon';
import { ELLIPSIS_DOTS, usePagination } from '@/hooks/use-pagination';
import { tv } from '@/utils/styles';

export interface PaginationProps {
  perPage?: number;
  handlePageChange: (pageNumber: number) => void;
  pageIndex: number;
  totalCount: number;
}

const Pagination = ({ perPage = 10, handlePageChange, pageIndex = 0, totalCount }: PaginationProps) => {
  // Calculate the total number of pages
  const totalPageCount = Math.ceil(totalCount / perPage);

  const paginationRange = usePagination({
    pageIndex,
    totalCount,
    siblingCount: 1,
    perPage,
  });

  const { base, content, paginationItem, paginationEllipsis, paginationPrevNext } = styles({
    isSinglePage: totalPageCount < 2,
  });

  return (
    <div className={base()}>
      <ul className={content()}>
        <button
          type="button"
          className={paginationPrevNext()}
          onClick={() => handlePageChange(pageIndex - 1)}
          disabled={pageIndex === 0}
          aria-label="Previous page"
        >
          <Icon name="chevron-left" size="base" color="grey.500" />
        </button>

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === ELLIPSIS_DOTS) {
            return (
              <li key={`ellipsis-${index}`}>
                <span className={paginationEllipsis()}>&hellip;</span>
              </li>
            );
          }

          return (
            <li key={`listing-page-${pageNumber}`}>
              <button
                type="button"
                className={paginationItem({ isActive: pageNumber === pageIndex })}
                onClick={() => handlePageChange(Number(pageNumber))}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}

        <button
          className={paginationPrevNext()}
          type="button"
          onClick={() => handlePageChange(pageIndex + 1)}
          disabled={pageIndex === totalPageCount - 1}
          aria-label="Next page"
        >
          <Icon name="chevron-right" size="base" color="grey.500" />
        </button>
      </ul>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex items-center justify-center',
    content: 'mx-auto mt-8 inline-flex content-center items-center p-1',
    paginationItem:
      'mx-0.5 inline-flex min-w-8 cursor-pointer justify-center rounded-default border-none bg-white p-2 text-center font-medium text-text-primary hover:bg-lightBlue-500 hover:text-white',
    paginationEllipsis: 'mx-2 border-none bg-transparent p-2 font-medium text-grey-500',
    paginationPrevNext:
      'cursor-pointer rounded-default border-none bg-transparent p-2 font-medium text-lightBlue-600 hover:bg-grey-200 hover:text-lightBlue-600',
  },
  variants: {
    isSinglePage: {
      true: {
        content: 'hidden',
      },
    },
    isActive: {
      true: {
        paginationItem: 'cursor-default bg-lightBlue-500 text-white',
      },
    },
  },
});

export { Pagination };
