'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isFunction } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/button';
import { TextInput } from '@/components/inputs';
import { Text } from '@/components/text';
import { Select } from '@/components/ui/inputs/select';
import { UserMessage } from '@/components/ui/user-message';
import { API_ERROR, SHIPMENT_FORM_FIELDS, TRACKING_TYPE } from '@/lib/constants';
import { CreateShipmentBody, ShipmentType } from '@/lib/models/shipment/types';
import { OptionGroup, ShipmentFormInputsInterface } from '@/types';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface ShipmentFormProps {
  onSubmit: (data: CreateShipmentBody) => Promise<void>;
  errors?: FieldErrors<ShipmentFormInputsInterface>;
  onError?: (hasError: boolean) => void;
  variant?: 'inline';
  buttonText?: string;
  disableButtonUntilValid?: boolean;
  buttonIcon?: string;
  className?: string;
}

const validationSchema = yup
  .object({
    [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER]: yup.string().required('A reference number is required'),
    [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE]: yup.string().required('A reference number type is required'),
  })
  .required();

const SHIPMENT_TYPE_OPTIONS: OptionGroup[] = [
  {
    title: '',
    items: [
      {
        value: 'containerNumber',
        label: 'Container ID',
      },
      /* {
        value: 'mblNumber',
        label: TRACKING_TYPE.MBOL,
      }, */
      {
        value: 'awbNumber',
        label: TRACKING_TYPE.AWB,
      },
    ],
  },
];

const getUserMessage = (error: string): string => {
  switch (error) {
    case API_ERROR.QUOTA_EXCEEDED:
      return 'You have reached the limit of how many shipments you can add on your plan.';
    case API_ERROR.UNAUTHORISED:
      return 'You appear to not be signed in. Please refresh the page and try logging in.';
    default:
      return 'Something went wrong, please check the details and try again.';
  }
};

const getTrackingCodeHint = (type: string): string => {
  switch (type) {
    case 'containerNumber':
      return 'This is the Carrier Code + the 7 digit Container Number. e.g. MSDU1234567';
    case 'mblNumber':
      return 'This is the Master Bill of Lading provided by the carrier';
    case 'awbNumber':
      return 'This is the Airway Bill Number provided by the airline';
    default:
      return '';
  }
};

const ShipmentForm = ({
  onSubmit,
  errors: errorsProp,
  onError,
  variant,
  buttonText = 'Submit',
  disableButtonUntilValid,
  buttonIcon,
  className,
}: ShipmentFormProps) => {
  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ShipmentFormInputsInterface>({
    resolver: yupResolver(validationSchema),
    errors: errorsProp,
  });

  const handleShipmentFormSubmit: SubmitHandler<ShipmentFormInputsInterface> = async (data) => {
    if (data) {
      const { referenceNumberType, referenceNumber } = data;
      let shipmentType: ShipmentType = 'intermodal';
      if (referenceNumberType === 'awbNumber') {
        shipmentType = 'air';
      }
      const shipment: CreateShipmentBody = { type: shipmentType, [referenceNumberType]: referenceNumber };

      trackUser.event('Submit shipment form', { shipment });
      try {
        await onSubmit(shipment);
        // Reset if no errors were returned
        reset({
          [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER]: '',
          [SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE]: referenceNumberType,
        });
      } catch (e: any) {
        setError('root.serverError', { message: getUserMessage(e.message) });
      }
    }
  };

  useEffect(() => {
    if (isFunction(onError)) {
      onError(!isEmpty(errors));
    }
  }, [errors, onError]);

  const shipmentReferenceType = watch(SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE);
  const trackingNumberHintMessage = getTrackingCodeHint(shipmentReferenceType);

  const { base, inputWrapper, hintText, buttonWrapper, button, error, typeSelect } = styles({ variant });

  return (
    <>
      <form className={base({ className })} onSubmit={handleSubmit(handleShipmentFormSubmit)}>
        <Controller
          control={control}
          name={SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER_TYPE}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              className={typeSelect()}
              value={value}
              id={rest.name}
              {...rest}
              errors={errors}
              hideErrorMessage
              label="Tracking number type"
              onSelect={(option) => onChange(option.value)}
              options={SHIPMENT_TYPE_OPTIONS}
              disabled={isSubmitting}
            />
          )}
        />
        <div className={inputWrapper()}>
          <Controller
            control={control}
            name={SHIPMENT_FORM_FIELDS.REFERENCE_NUMBER}
            render={({ field }) => (
              <TextInput
                {...field}
                disabled={isSubmitting}
                errors={errors}
                hideErrorMessage
                label="Tracking number"
                placeholder="e.g MSDU1234567"
              />
            )}
          />
          {trackingNumberHintMessage && <Text className={hintText()}>{trackingNumberHintMessage}</Text>}
        </div>
        <div className={buttonWrapper()}>
          <Button
            className={button()}
            disabled={isSubmitting || (disableButtonUntilValid && !isValid)}
            isLoading={isSubmitting}
            iconLeft={buttonIcon}
          >
            {buttonText}
          </Button>
        </div>
      </form>
      {errors.root?.serverError ? (
        <UserMessage body={errors.root.serverError.message} heading="Error" variant="error" className={error()} />
      ) : null}
    </>
  );
};

const styles = tv({
  slots: {
    base: 'flex w-full flex-col space-y-4',
    button: 'w-full',
    buttonWrapper: 'flex w-full justify-end',
    inputWrapper: 'relative flex-1',
    hintText: 'mb-0 mt-1 px-2 text-sm text-text-secondary',
    typeSelect: 'min-w-32',
    error: '!mb-0 !mt-4',
  },
  variants: {
    variant: {
      inline: {
        base: 'w-auto gap-2 sm:flex-row sm:items-end',
        button:
          'h-[--input-height] pl-base pr-base text-baseSm font-normal tracking-[0.02rem] sm:w-auto sm:pl-base sm:pr-base',
        buttonWrapper: 'mt-2 self-end sm:!mt-5 sm:ml-2 sm:w-auto sm:translate-y-px md:!mt-5',
        typeSelect: 'min-w-48',
        error: 'ml-0 w-96',
        hintText: 'absolute left-0 right-0 top-full',
      },
    },
  },
});

export { ShipmentForm };
