import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { Link } from '@/components/link';
import { LinkBankBlockProps } from '@/types/block-types';
import { media } from '@/utils/media';

const blockVariants = variant({
  scale: 'linkBankBlock',
  variants: {
    light: {
      color: 'text.primary',
      backgroundColor: 'white',
    },
    grey: {
      color: 'text.primary',
      backgroundColor: 'grey.50',
    },
  },
});

const LinkBankBlock = ({
  heading,
  links,
  noTopPadding = false,
  variant: blockVariant = 'light',
  id = 'link-bank',
}: LinkBankBlockProps) => {
  return (
    <Wrapper variant={blockVariant} id="link-bank">
      <Block id={id} heading={heading} headingVariant="h2" noTopPadding={noTopPadding}>
        <Links>
          {links &&
            links.links.map((link) => (
              <StyledLink key={link.url} href={link.url}>
                {link.value}
              </StyledLink>
            ))}
        </Links>
      </Block>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  ${blockVariants};
`;

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${themeGet('space.md')};
  grid-row-gap: ${themeGet('space.base')};

  ${media.md} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledLink = styled(Link)`
  line-height: ${themeGet('lineHeights.snug')};
  text-decoration: none;

  &:hover {
    color: ${themeGet('colors.lightBlue.600')};
    text-decoration: underline;
  }
`;

export { LinkBankBlock };
