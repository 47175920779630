import { has, isEmpty } from 'lodash';
import { ChevronDown, Settings2 } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { RadioTabs } from '@/components/inputs/radio-tabs';
import { LocationAutocompletePair } from '@/components/location-autocomplete-pair';
import { SearchLabel } from '@/components/modules/schedules/search-label';
import { AdvancedDatePicker } from '@/components/ui/inputs/advanced-date-picker';
import { dateRangeToSimpleDates } from '@/components/ui/inputs/advanced-date-picker/utils';
import { SavedItemButton } from '@/components/ui/saved-item-button';
import { useSavedItems } from '@/hooks/use-saved-items';
import { AIRPORT, MODE_AIR, MODE_WATER, SAVED_ITEM_TYPE, SEAPORT } from '@/lib/constants';
import { SavedItemModel } from '@/lib/models/saved-item/types';
import {
  scheduleFiltersActiveCountState,
  standaloneSchedulesCanSearch,
  standaloneSchedulesDateRangeState,
  standaloneSchedulesDestinationState,
  standaloneSchedulesModeState,
  standaloneSchedulesOriginState,
  standaloneSchedulesSearchState,
  standaloneSchedulesState,
} from '@/state/standalone-schedules';
import { StandaloneScheduleQueryParams } from '@/types';
import { PlaceInterface, SegmentModeType } from '@/types/api-types';
import { formatDateTimeForUrl } from '@/utils/format/date-time';
import { getModePlaceholderText } from '@/utils/format/mode';
import { QUERY_PARAM } from '@/utils/helpers/api-urls/constants';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

export interface ScheduleSearchBarProps {
  onSearch: (query: StandaloneScheduleQueryParams) => void;
  onToggleFilters?: () => void;
  isEnabled?: boolean;
  hideFiltersButton?: boolean;
  className?: string;
  canToggleSearchBar?: boolean;
}

const ScheduleSearchBar = ({
  onSearch,
  onToggleFilters,
  isEnabled = false,
  hideFiltersButton,
  canToggleSearchBar = true,
  className,
}: ScheduleSearchBarProps) => {
  const router = useRouter();
  const { createSavedItem, getSavedItemByPath, deleteSavedItem, isEnabled: isSavedItemsEnabled } = useSavedItems();
  const [isOpen, setIsOpen] = useState(true);
  const searchState = useRecoilValue(standaloneSchedulesSearchState);
  const [origin, setOrigin] = useRecoilState(standaloneSchedulesOriginState);
  const [destination, setDestination] = useRecoilState(standaloneSchedulesDestinationState);
  const [dateRange, setDateRange] = useRecoilState(standaloneSchedulesDateRangeState);
  const resetDateRange = useResetRecoilState(standaloneSchedulesDateRangeState);
  const [mode, setMode] = useRecoilState(standaloneSchedulesModeState);
  const canSearch = useRecoilValue(standaloneSchedulesCanSearch);
  const scheduleResults = useRecoilValue(standaloneSchedulesState);
  const scheduleFiltersActiveCount = useRecoilValue(scheduleFiltersActiveCountState);

  const parsedDates = dateRangeToSimpleDates(dateRange);
  // Mode Type
  const isAir = mode === MODE_AIR;
  const isWater = mode === MODE_WATER;
  const searchModeType = isAir ? AIRPORT : SEAPORT;

  const queryData = {
    [QUERY_PARAM.ORIGIN]: origin?.name || '',
    [QUERY_PARAM.DESTINATION]: destination?.name || '',
    [QUERY_PARAM.MODE]: mode,
    [QUERY_PARAM.DATE_FROM]: parsedDates.dateFrom ? formatDateTimeForUrl(parsedDates.dateFrom) : '',
    [QUERY_PARAM.DATE_TO]: parsedDates.dateTo ? formatDateTimeForUrl(parsedDates.dateTo) : '',
  };

  const savedItem = getSavedItemByPath({ path: router.asPath, type: SAVED_ITEM_TYPE.SCHEDULE });

  const handleModeChange = (newMode: SegmentModeType) => {
    setMode(newMode);
    setOrigin(undefined);
    setDestination(undefined);
  };

  const handleChangeLocations = (value: { origin?: PlaceInterface; destination?: PlaceInterface }) => {
    // Check Object.hasOwn since the value could be undefined, which means the
    // value was cleared
    if (has(value, 'origin')) {
      setOrigin(value.origin);
    }
    if (has(value, 'destination')) {
      setDestination(value.destination);
    }
  };

  const handleSearchClick = () => {
    // Search must be handled by pushing query params to the current route
    if (canSearch) {
      onSearch(queryData);

      trackUser.event('Schedule Search', {
        origin: origin?.name || '',
        destination: destination?.name || '',
        mode: searchModeType,
        dateType: dateRange.type,
      });
    }
  };

  const handleClickPin = async () => {
    if (savedItem) {
      await deleteSavedItem(savedItem);
    } else {
      const newSavedItem: SavedItemModel = {
        data: JSON.stringify(queryData),
        label: `${origin?.identifier?.value} to ${destination?.identifier?.value}`,
        type: SAVED_ITEM_TYPE.SCHEDULE,
      };
      await createSavedItem(newSavedItem);
    }
  };

  const { base, searchButton, mobileAccordionTrigger, filterButton, badge, chevron, filtersGroup, locationWrapper } =
    styles();

  return (
    <>
      {canToggleSearchBar && (
        <button
          type="button"
          aria-label="Open search settings"
          className={mobileAccordionTrigger()}
          onClick={() => setIsOpen(!isOpen)}
        >
          <SearchLabel origin={origin} destination={destination} />
          <ChevronDown className={chevron({ isOpen })} />
        </button>
      )}
      <div className={base({ className, isOpen })}>
        <div className={filtersGroup()}>
          <RadioTabs
            name="toggle-transport-mode"
            label="Ship by"
            onChange={handleModeChange}
            selected={mode}
            options={[
              {
                label: (
                  <>
                    <Icon name="plane" color={isAir ? 'white' : 'grey.900'} className="mr-2 lg:hidden xl:block" />
                    <span>Air</span>
                  </>
                ),
                value: MODE_AIR,
              },
              {
                label: (
                  <>
                    <Icon name="ship" color={isWater ? 'white' : 'grey.900'} className="mr-2 lg:hidden xl:block" />
                    <span>Ocean</span>
                  </>
                ),
                value: MODE_WATER,
              },
            ]}
          />
          <div className={locationWrapper()}>
            <LocationAutocompletePair
              placeholderText={getModePlaceholderText(mode || MODE_AIR)}
              origin={origin}
              destination={destination}
              onChange={handleChangeLocations}
              placeTypes={[searchModeType]}
            />
            <AdvancedDatePicker
              onChange={setDateRange}
              value={dateRange}
              onSearch={handleSearchClick}
              canSearch={isEnabled && canSearch}
              onReset={resetDateRange}
            />
          </div>
        </div>
        <div className="flex grow items-center gap-1.5">
          <Button
            iconLeft={isEnabled ? 'search' : 'lock'}
            isLoading={searchState.isSearching}
            disabled={!isEnabled || !canSearch}
            onClick={handleSearchClick}
            className={searchButton()}
            data-cy="schedule-search-button"
          >
            Search
          </Button>
          {isSavedItemsEnabled ? (
            <SavedItemButton
              disabled={!isEnabled || !canSearch}
              onClick={handleClickPin}
              type={SAVED_ITEM_TYPE.SCHEDULE}
              isSaved={!!savedItem}
            />
          ) : null}
          {!hideFiltersButton && (
            <Button
              onClick={onToggleFilters}
              className={filterButton({ activeFilters: Boolean(scheduleFiltersActiveCount) })}
              disabled={isEmpty(scheduleResults.schedules)}
              variant="primaryLightOutline"
            >
              <Settings2 size={16} className="mr-1.5" />
              <span>Filters</span>
              {Boolean(scheduleFiltersActiveCount) && (
                <Badge className={badge()} variant="dark">
                  {scheduleFiltersActiveCount}
                </Badge>
              )}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const styles = tv({
  slots: {
    base: 'relative z-10 flex max-h-96 flex-col gap-4 border-b border-grey-300 bg-grey-100 px-4 py-3 transition-all duration-200 lg:flex-row lg:items-end lg:gap-2',
    filtersGroup: 'flex flex-col gap-4 lg:flex-row lg:items-end lg:justify-between lg:gap-2',
    mobileAccordionTrigger:
      'relative z-11 flex w-full items-center justify-between bg-grey-200 p-4 text-baseSm hover:bg-grey-300 lg:hidden',
    toggleButton: 'md:hidden',
    searchButton:
      'z-2 h-[--input-height] pl-base pr-base text-baseSm font-normal tracking-[0.02rem] max-sm:w-full sm:pl-base sm:pr-base',
    filterButton: 'ml-auto items-center md:flex',
    badge: 'r-4 absolute top-2.5 ml-2 bg-lightBlue-500 px-2 py-1 text-sm',
    chevron: 'rotate-0 duration-150 ease-in-out',
    locationWrapper: 'flex flex-col gap-4 lg:flex-row lg:items-end lg:gap-2',
  },
  variants: {
    activeFilters: {
      true: {
        filterButton: ' !pr-12',
      },
    },
    isOpen: {
      true: {
        base: 'translate-y-0',
        chevron: 'rotate-180',
      },
      false: {
        base: 'max-h-0 -translate-y-96 py-0 lg:max-h-full lg:translate-y-0 lg:py-3',
      },
    },
  },
});

export { ScheduleSearchBar };
