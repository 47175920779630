import { Block } from '@/components/block';
import { ButtonGroup } from '@/components/button-group';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { ContentMediaBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ContentMediaBlock = ({
  id,
  heading,
  body,
  children,
  image,
  isReversed = false,
  buttons = [],
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  ...props
}: ContentMediaBlockProps) => {
  const { base, grid, imageCol, contentCol } = contentMediaBlockStyles({
    isReversed,
  });
  return (
    <Block id={id} className={base()} noTopPadding={noTopPadding} {...props}>
      <div className={grid()}>
        <div className={imageCol()}>
          <Image data={image} className="rounded-md" />
        </div>

        <div className={contentCol()}>
          <div>
            {children || (
              <>
                {preHeading && (
                  <Text variant={preHeadingVariant} className="mb-4 text-grey-500">
                    {preHeading}
                  </Text>
                )}
                {heading && (
                  <Heading variant={headingVariant} as="h2" className="mb-md">
                    {heading}
                  </Heading>
                )}
                {body && <RichText>{body}</RichText>}

                {buttons.length ? <ButtonGroup buttons={buttons} className="mt-8 justify-start" /> : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Block>
  );
};

const contentMediaBlockStyles = tv({
  slots: {
    base: 'pb-12 pt-12 xl:pb-16 xl:pt-16',
    grid: 'grid-auto-rows-min grid justify-center gap-8 lg:grid-cols-2 lg:grid-rows-none lg:justify-normal lg:gap-8',
    imageCol: 'mx-auto w-full md:max-lg:w-10/12',
    contentCol:
      'mx-auto flex w-full justify-center md:items-center md:max-lg:w-10/12 lg:order-first lg:justify-normal lg:pr-8 xl:pr-16',
  },
  variants: {
    isReversed: {
      true: {
        contentCol: 'lg:order-last lg:pl-8 xl:pl-16 xl:pr-5',
      },
    },
  },
});

export { ContentMediaBlock };
