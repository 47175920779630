import { ROUTING_SEARCH_URL, SAVED_ITEM_TYPE, SCHEDULE_SEARCH_URL, TRACKING_URL } from '@/lib/constants';
import { SavedItemModel } from '@/lib/models/saved-item/types';
import { logEvent } from '@/utils/logger';
import { searchStateToQueryParams } from '@/utils/route';

export const getSavedItemLink = (savedItem: SavedItemModel) => {
  try {
    if (savedItem.type === SAVED_ITEM_TYPE.ROUTE) {
      const query = searchStateToQueryParams(JSON.parse(savedItem.data));
      return `${ROUTING_SEARCH_URL}?${new URLSearchParams(query)}`;
    }
    if (savedItem.type === SAVED_ITEM_TYPE.SCHEDULE) {
      const query = JSON.parse(savedItem.data);
      return `${SCHEDULE_SEARCH_URL}?${new URLSearchParams(query)}`;
    }
    if (savedItem.type === SAVED_ITEM_TYPE.SHIPMENT) {
      const query = JSON.parse(savedItem.data);
      return `${TRACKING_URL}?${new URLSearchParams(query)}`;
    }
  } catch (e) {
    logEvent.error('Error getting savedItemLink', { e, savedItem });
  }
  return '';
};
