import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { Block } from '@/components/block';
import { Box } from '@/components/box';
import { NARROW_CENTER_CONTENT_MAX_WIDTH } from '@/lib/constants';
import { LogoPromoBlockProps } from '@/types/block-types';

const blockVariants = variant({
  scale: 'LogoPromoBlock',
  variants: {
    light: {
      color: 'text.primary',
      backgroundColor: 'white',
      h2: {
        marginBottom: 'base',
      },
      p: {
        color: 'grey.600',
      },
    },
    grey: {
      color: 'text.primary',
      backgroundColor: 'grey.50',
      h2: {
        marginBottom: 'base',
      },
      p: {
        color: 'grey.600',
      },
    },
  },
});

const LogoPromoBlock = ({
  blockAlignment,
  body,
  id,
  heading,
  variant: blockVariant = 'light',
  noTopPadding = false,
  logoList,
}: LogoPromoBlockProps) => {
  const logoArr = logoList.split(',');
  return (
    <Wrapper variant={blockVariant}>
      <Block
        id={id}
        heading={heading}
        headingVariant="h1"
        headingElement="h2"
        body={body}
        blockAlignment={blockAlignment}
        noTopPadding={noTopPadding}
      >
        <Grid>
          {logoArr.map((logoPath) => {
            return <StyledImg key={logoPath} src={logoPath} alt="brand logo" />;
          })}
        </Grid>
      </Block>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  ${blockVariants};
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${NARROW_CENTER_CONTENT_MAX_WIDTH};
  margin: 0 auto;
  margin-top: -${themeGet('space.md')};
`;

const StyledImg = styled.img`
  max-height: ${themeGet('space.8')};
  margin: ${themeGet('space.base')};
`;

export { LogoPromoBlock };
