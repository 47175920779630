import { parse } from 'cookie';
import { differenceInDays } from 'date-fns/fp';
import { isEmpty } from 'lodash';
import { Session } from 'next-auth';

import {
  ACCOUNT_SIDE_NAV_ITEMS,
  COOKIE_REDIRECT_URL,
  COOKIE_SUBSCRIPTIONS,
  DEFAULT_ENTITLEMENTS,
  PLAN_TIER_FREE,
  PLAN_TIER_PAID,
  SIGN_IN_PAGE_URL,
  USER_ACCESS_FEATURES,
  USER_PAGE_URL,
} from '@/lib/constants';
import { UserSubscriptionInterface, UserTrialInterface } from '@/types';
import {
  AdapterUserInterface,
  OrgsPublicTableInterface,
  OrgsUsersInvitesPublicTableInterface,
} from '@/types/database-types';
import { getNextOccuranceOfDate, getPreviousMonthDate } from '@/utils/format/date-time';
import { accountPermissions } from '@/utils/permissions/account';
import { getUserIsOAuthUser } from '@/utils/permissions/helpers';

export interface SendPasswordResetEmailParams {
  identifier: string;
  url: string;
  expires: Date;
  from: string;
  token: string;
}

/**
 * Check if the user has access to the feature
 *
 * @param featureName The name of the feature to check
 * @param session The session object
 * @param showAuth Whether the authentication feature is enabled (default = true)
 * @returns Whether the user has access to the feature
 */
export const canAccessFeature = (
  featureName: (typeof USER_ACCESS_FEATURES)[keyof typeof USER_ACCESS_FEATURES],
  session?: Session | null,
  showAuth = true,
): boolean => {
  if (!showAuth) return true;
  // If not logged in, user cannot access feature
  if (!session) return false;

  // Merges default entitlements + accesslevel overrides
  const entitlements = {
    ...DEFAULT_ENTITLEMENTS,
    ...(session?.entitlements || {}),
  };
  // Check feature access, returns false if undefined or falsey
  return !!entitlements[featureName];
};

export const hasReachedSavedItemsLimit = (savedItemsCount: number = 0, session?: Session | null): boolean => {
  if (session) {
    const { savedItemsLimit } = session.entitlements;
    if (savedItemsCount >= Number(savedItemsLimit)) return true;
    return false;
  }
  // Non logged in users cannot save
  return true;
};

/**
 * Check if the user has access to the page based on the needsAdminAccess
 * property of the nav item associated with the page
 *
 * @param href - The href of the nav item
 * @param session - The current auth session
 * @returns boolean - Whether the user has access to the page
 */
export const canAccessAccountPage = (href: string, session?: Session | null): boolean => {
  if (!session) return false;

  // Find the nav item that has a matching href
  const navGroup = ACCOUNT_SIDE_NAV_ITEMS.find((group) => group.items.some((item) => item.href === href));
  const navItem = navGroup && navGroup.items.find((item) => item.href === href);
  // If there's a matching nav item, check if the user has access to it
  if (navItem) {
    // If the nav item requires admin access and the user isn't an admin or owner, return false
    if ((navGroup.needsAdminAccess || navItem.needsAdminAccess) && !accountPermissions.canRead({ session })) {
      return false;
    }
    // Otherwise, return true
    return true;
  }
  // If there's no matching nav item, assume the user has access to the page
  return true;
};

/**
 * Determines the start and end period of a users usage / access
 * @param startDate - string (date)
 * @returns [periodStartDate, periodEndDate]
 */
const getUsageResetDates = (startDate: string | Date): [string, string] => {
  // Determine usage reset dates based on a calculation a rolling month-to-month point to now
  const periodEnd = getNextOccuranceOfDate(startDate);
  const periodStart = getPreviousMonthDate(periodEnd);
  return [periodStart, periodEnd];
};

/**
 * Builds a subscription object for the user session
 */
export const getSubscriptionSessionObj = (
  userData?: AdapterUserInterface,
  org?: OrgsPublicTableInterface | null,
): UserSubscriptionInterface => {
  if (org && !isEmpty(org.subscription)) {
    // Override users period start/end with calculation
    const [periodStart, periodEnd] = getUsageResetDates(org.subscription.periodStart);

    // If member of org (which has an active subscription), assume paid plan
    return {
      ...org.subscription,
      orgId: org.id,
      tier: PLAN_TIER_PAID,
      periodStart: new Date(periodStart).toISOString(),
      periodEnd: new Date(periodEnd).toISOString(),
    };
  }

  // Starter / Free Account
  // ----------------------
  const [periodStart, periodEnd] = getUsageResetDates(userData?.createdAt || new Date());

  return {
    tier: PLAN_TIER_FREE,
    planName: 'Starter',
    status: 'active',
    periodStart: new Date(periodStart).toISOString(),
    periodEnd: new Date(periodEnd).toISOString(),
  };
};

/**
 * Determins a Users Trial expiry and status
 */
export const getUserTrialStatus = (userData: AdapterUserInterface): UserTrialInterface => {
  const { metadata } = userData;

  if (metadata?.trialExpiryDate) {
    // There is an existing trial end date set, use it
    const today = new Date();
    const existingTrialDate = new Date(metadata.trialExpiryDate);
    const daysBetween = differenceInDays(today, existingTrialDate);
    const isActive = daysBetween >= 0;

    return {
      expiresIn: isActive ? daysBetween : 0,
      isActive,
    };
  }

  return {
    isActive: false,
    expiresIn: 0,
  };
};

/**
 * Forces a reload of the auth session
 * https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
 */
export const reloadAuthSession = () => {
  const event = new Event('visibilitychange');

  document.dispatchEvent(event);
};

export const redirectToNextOnboardingScreen = async (
  session: Session | undefined,
  pendingInvites: OrgsUsersInvitesPublicTableInterface[] | null,
  cookies: string,
) => {
  // Parse the cookies string into an object
  const parsedCookies = parse(cookies);

  const subscriptionsOn = parsedCookies[COOKIE_SUBSCRIPTIONS] === 'true';

  const isOAuthUser = session ? getUserIsOAuthUser(session?.user) : false;

  // Checks if user has provided enough details in their profile
  const hasProvidedAdditionalInfo = () => {
    if (isOAuthUser) {
      // No password needed for oAuth users
      return !!(session?.user.name && session?.user.country && session?.user.usagePurpose);
    }
    return !!(session?.user.hasPassword && session?.user.name && session?.user.country && session?.user.usagePurpose);
  };

  if (!session) {
    // User is not logged in, redirect to login page
    return SIGN_IN_PAGE_URL;
  }

  if (!hasProvidedAdditionalInfo()) {
    // User has not provided additional information, redirect to profile page
    return USER_PAGE_URL;
  }

  if (!isEmpty(pendingInvites)) {
    return `${USER_PAGE_URL}/invites`;
  }

  if (subscriptionsOn) {
    const alreadyBelongsToOrg = !isEmpty(session?.user?.orgs);
    const isNewUser = session?.user?.isNewUser;

    if (!alreadyBelongsToOrg && isNewUser) {
      return `${USER_PAGE_URL}/plan`;
    }
  }

  // User has provided additional information, check for redirect URL
  const redirectUrl = parsedCookies[COOKIE_REDIRECT_URL] || '/';

  // If user has provided additional info and the redirect URL
  // is an auth page or for whatever reason an API endpoint,
  // redirect to the homepage
  if (redirectUrl.includes('/auth/') || redirectUrl.includes('/api/')) {
    return '/';
  }

  // Ensure callback url was properly formed
  if (redirectUrl && !redirectUrl.includes('undefined')) {
    return redirectUrl;
  }

  return '/';
};
