import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SubscribeForm } from '@/blocks/subscribe-block/subscribe-form';
import { Block } from '@/components/block';
import { mailingListProvider } from '@/lib/api/providers/fluent/mailing-list';
import { SubscribeFormInputs } from '@/types';
import { SubscribeBlockProps } from '@/types/block-types';
import { logEvent } from '@/utils/logger';
import { trackUser } from '@/utils/tracking';

export const subscribeFormValidationSchema = yup
  .object({
    email: yup.string().email('Enter a valid email address').required('Enter your email address'),
  })
  .required();

const SubscribeBlock = ({
  id,
  heading,
  body,
  variant = 'dark',
  successHeading,
  successBody,
  isUnderPageHeader,
}: SubscribeBlockProps) => {
  const formMethods = useForm<SubscribeFormInputs>({
    resolver: yupResolver(subscribeFormValidationSchema),
  });

  const onSubmit: SubmitHandler<SubscribeFormInputs> = async (data) => {
    trackUser.event('Subscribe To News');

    try {
      await mailingListProvider.subscribe(data);
    } catch (error: any) {
      logEvent.error('Subscribe To News Failed', { error });
      formMethods.setError('email', {
        type: 'manual',
        message: error.message,
      });
    }
  };

  return (
    <Block id={id} className="relative pb-md pt-md xl:pb-md xl:pt-md">
      {body && successHeading && successBody && (
        <FormProvider {...formMethods}>
          <SubscribeForm
            heading={heading}
            body={body}
            successHeading={successHeading}
            successBody={successBody}
            onSubmit={onSubmit}
            variant={variant}
            isUnderPageHeader={isUnderPageHeader}
          />
        </FormProvider>
      )}
    </Block>
  );
};

export { SubscribeBlock };
