import { useFormContext } from 'react-hook-form';
import striptags from 'striptags';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { TextInput } from '@/components/inputs/text-input';
import { Text } from '@/components/text';
import { SubscribeFormInputs } from '@/types';
import { BlockBody } from '@/types/block-types';
import { tv } from '@/utils/styles';

export interface SubscribeFormProps {
  heading?: string;
  body: string | BlockBody;
  variant?: 'dark' | 'light';
  successHeading: string;
  successBody: string;
  isUnderPageHeader?: boolean;
  onSubmit: (data: SubscribeFormInputs) => void;
}

const SubscribeForm = ({
  heading,
  body,
  variant = 'dark',
  successHeading,
  successBody,
  isUnderPageHeader,
  onSubmit,
  ...props
}: SubscribeFormProps) => {
  const {
    base,
    button,
    form,
    formFields,
    formFieldsInner,
    headingWrapper,
    successHeadingStyles,
    successWrapper,
    text,
  } = styles({
    variant,
    isUnderPageHeader,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useFormContext<SubscribeFormInputs>();

  return (
    <div className={base()} {...props}>
      {!isSubmitSuccessful ? (
        <form onSubmit={handleSubmit(onSubmit)} className={form()}>
          <div className={headingWrapper()}>
            <div>
              {heading && (
                <Heading
                  variant="h3"
                  dangerouslySetInnerHTML={{ __html: striptags(heading, ',<br>') }}
                  as="h2"
                  className="mb-0"
                />
              )}
              {body && <Text className={text()}>{body}</Text>}
            </div>
          </div>

          <div className={formFields()}>
            <div className={formFieldsInner()}>
              <TextInput
                label="Email address"
                errors={errors}
                placeholder="Your email address"
                hideLabel
                {...register('email', { required: true })}
              />

              <div>
                <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting} className={button()}>
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className={successWrapper()}>
          <Heading variant="h2" as="h2" className={successHeadingStyles()}>
            {successHeading}
          </Heading>
          <Heading variant="h4" as="p" className="mb-0">
            {successBody}
          </Heading>
        </div>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'rounded-lg p-6 sm:px-16 sm:py-12 xl:-ml-8 xl:-mr-8 xl:px-32',
    block: 'relative pb-md pt-md xl:pb-md xl:pt-md',
    button: 'mt-3 w-full sm:ml-3 sm:mt-0 sm:w-auto',
    form: 'gap-md lg:grid lg:grid-cols-2 lg:gap-lg',
    formFields: 'flex w-full items-center',
    formFieldsInner: 'block w-full sm:flex',
    headingWrapper: 'flex max-lg:mb-md',
    successHeadingStyles: 'mb-sm',
    successWrapper: 'flex flex-col text-center',
    text: 'm-0 opacity-70',
  },
  variants: {
    variant: {
      dark: {
        base: 'bg-brandDarkBlue text-white',
      },
      light: {},
    },
    isUnderPageHeader: {
      true: {
        block: '!-mt-20 !pb-0 !pt-0',
      },
    },
  },
});

export { SubscribeForm };
